import './App.css';
import graveyard from './assets/images/graveyard.png';
import rrssBackground from './assets/images/rrss-background.png';
import ghosts from './assets/images/ghosts.png';
import gfarcaster from './assets/images/gfarcaster.png';
import gtwitter from './assets/images/gtwitter.png';
import hamburger from './assets/images/hmenu.png';
import { useState } from 'react';
function App() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const closeMenu = () => {
    setIsMenuOpen(false);
  }

  return (
    <div className="page-container">
      <header className="header">
        <div className="header-left">
          <h5>LOSS SHARE, EARN</h5>
        </div>
        <div className={`header-right ${isMenuOpen ? 'open' : ''}`}>
          <a href="https://x.com/JoinHorror" rel="noreferrer" target="_blank"><h5>TWITTER</h5></a>
          <a href="https://warpcast.com/~/channel/joinhorror" rel="noreferrer" target="_blank"><h5>FARCASTER</h5></a>
          <a href="/#" rel="noreferrer" target="_blank"><h5>SHARE YOUR STORY</h5></a>
          <a href="/#" rel="noreferrer" target="_blank"><button>
            <h5>BUY $HORROR</h5>
          </button></a>
        </div>
        <img className="hamburger" onClick={toggleMenu} src={hamburger} alt="hamburger" />
        {isMenuOpen && <div className="overlay" onClick={closeMenu}></div>}
      </header>
      <div className="section-1">
        <h1>$HORROR</h1>
        <h2>THE GHOSTS OF FAILURES PAST</h2>
      </div>
      <div className="section-2">
        <h4>WHETHER YOU SOLD THE DIP, FOMO'D THE TOP, OR SIMPLY COULDN'T MAKE IT. <span>$HORROR REWARDS YOU FOR THE MOMENTS YOU'D RATHER FORGET.</span> THIS HALLOWEEN, LET YOUR NIGHTMARES EARN YOU SOME SPOOKTACULAR GAINS. 👻#NGMI BUT YOU'RE GONNA WIN WITH $HORROR!</h4>
        <img src={graveyard} alt="graveyard" />
      </div>
      <div className="section-3">
        <div className='section-3-top'>
          <h2>HOW IT WORKS?</h2>
          <h4 className='white'>SHARE YOUR CRYPTO HORROR STORY, TAG US AND WIN!</h4>
        </div>
        <div className='section-3-middle'>
          <a href="https://warpcast.com/~/channel/joinhorror" rel="noreferrer" target="_blank"><img className='rrss-img' src={gfarcaster} alt="rrss-farcaster" /></a>
          <a href="https://x.com/JoinHorror" rel="noreferrer" target="_blank"><img className='rrss-img' src={gtwitter} alt="rrss-twitter" /></a>
          <img className='rrss-background' src={rrssBackground} alt="rrss" />
        </div>
        <div className='section-3-bottom'>
          <h3 className='white'>OUR TEAM WILL PICK THE SCARIEST STORIES,</h3>
          <h3 className='white'>THE ONES THAT CAPTURE THE TRUE HORRORS OF CRYPTO LOSSES.</h3>
          <h3>WIN $HORROR TOKENS IF YOUR STORY IS SELECTED!</h3>
        </div>
      </div>
      <div className='section-4'>
        <h3>FUTURE ROUNDS WILL ALLOW THE COMMUNITY TO VOTE FOR THEIR FAVORITE STORIES</h3>
        <h3 className='stay-tuned'>STAY TUNED!!</h3>
        <img src={ghosts} alt="ghosts" />
        <small>The information provided in this disclaimer does not constitute investment advice, financial advice, trading advice, usage or playing advice or any other sort of advice. There is no formal team or roadmap. The coin is for entertainment purposes only.</small>
      </div>
    </div>
  );
}

export default App;
